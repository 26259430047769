import buildURL from "axios/lib/helpers/buildURL";
import oauth from "../../common/social.config";

const state = {
  oauth,
  redirectPath: localStorage.getItem("redirectPath") || "/",
};

const getters = {
  oauthUrl: (state) => (provider) => {
    const url = state.oauth[provider].url;
    const params = state.oauth[provider].params;
    return buildURL(url, params);
  },
  getRedirectPath: (state) => state.redirectPath,
};

const actions = {
  saveRedirectPath({ commit }, path) {
    commit("SET_REDIRECT_PATH", path);
  },
  clearRedirectPath({ commit }, path) {
    commit("PURGE_REDIRECT_PATH");
  },
};

const mutations = {
  SET_REDIRECT_PATH(state, path) {
    state.redirectPath = path;
    localStorage.setItem("redirectPath", state.redirectPath);
  },
  PURGE_REDIRECT_PATH(state) {
    state.redirectPath = "/";
    localStorage.removeItem("redirectPath");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
