import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      title: "Home",
    },
    component: () => import("../pages/Index.vue"),
  },
  {
    path: "/auth/oidc/callback",
    name: "callback",
    component: () => import("../components/Callback.vue"),
  },
  {
    path: "/contacts/organizations/:id",
    name: "organization",
    component: () => import("../pages/Organization.vue"),
  },
  {
    path: "/contacts/organizations/",
    name: "organizations",
    meta: {
      title: "Organizations",
    },
    component: () => import("../pages/Organizations.vue"),
  },
  {
    path: "/grants/:id",
    name: "grant",
    component: () => import("../pages/Award.vue"),
  },
  {
    path: "/grants/",
    name: "grants",
    meta: {
      title: "Grants",
    },
    component: () => import("../pages/Awards.vue"),
  },
  {
    path: "/programs/:id",
    name: "program",
    component: () => import("../pages/Program.vue"),
  },
  {
    path: "/programs/",
    name: "programs",
    meta: {
      title: "Programs",
    },
    component: () => import("../pages/Programs.vue"),
  },
  {
    path: "/contacts/people/:id",
    name: "person",
    component: () => import("../pages/Person.vue"),
  },
  {
    path: "/contacts/people/",
    name: "people",
    meta: {
      title: "People",
    },
    component: () => import("../pages/People.vue"),
  },
  {
    path: "/projects/:id",
    name: "project",
    component: () => import("../pages/Project.vue"),
  },
  {
    path: "/projects/",
    name: "projects",
    meta: {
      title: "Projects",
    },
    component: () => import("../pages/Projects.vue"),
  },
  {
    path: "/contacts/",
    name: "contacts",
    meta: {
      title: "Contacts",
    },
    component: () => import("../pages/Contacts.vue"),
  },
  {
    path: "/sidebar/",
    name: "sidebar",
    component: () => import("../components/Sidebar.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: {
      title: "Notifications",
    },
    component: () => import("../pages/Notifications.vue"),
  },
  {
    path: "/dashboard/",
    name: "dashboard",
    meta: {
      title: "CGEPI Dashboard",
    },
    component: () => import("../pages/CGEPI-Dashboard.vue"),
  },
  {
    path: "/bcpi-ta-map/",
    name: "bcpi-ta-map",
    meta: {
      title: "BCPI Technical Assistance and Research Map",
    },
    component: () => import("../pages/BCPI-TA-Map.vue"),
  },
  {
    path: "/bcpi-qr-map/",
    name: "bcpi-qr-map",
    meta: {
      title: "BCPI QR Cities Map",
    },
    component: () => import("../pages/BCPI-QR-Map.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../pages/404.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () => import("../pages/403.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.auth.isAuthenticated;
  document.title = `${to.meta.title ? to.meta.title + " - " : ""}GEPI.io`;

  if (to.path !== "/login" && to.name !== "callback" && !isAuthenticated) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
