<template>
<div class="tooltip"><div :class="icon" v-bind="$attrs"></div>
  <span class="tooltiptext">{{helpText}}</span>
</div>
</template>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--very-dark-grey);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  font-family: "roboto";
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--very-dark-grey) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.icon-form-help {
  height: 20px;
  width: 20px;
  margin: auto;
  padding-left: 24px;
  background-color: var(--aqua);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/question-mark-circle.svg");
}
</style>

<script>
  export default {
    name: 'Tooltip',
    props: {
      helpText: {
        type: String,
        required: true
      },
      icon: {
          type: String,
          required: false,
          default: 'icon-form-help'
      }
    },
  }
  </script>
