const oauth = {
  jhu: {
    url: "https://login.jh.edu/idp/profile/oidc/authorize",
    params: {
      client_id: process.env.VUE_APP_CLIENT_ID, // change for prod
      response_type: "code",
      scope: "openid profile",
      redirect_uri: process.env.VUE_APP_REDIRECT_URI, //change for prod
    },
  },
};

export default oauth;
