export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH = "AUTH_REFRESH";
export const PURGE_AUTH = "PURGE_AUTH";
export const SET_AUTH = "SET_AUTH";
export const AUTH_LOGIN = "AUTH_LOGIN";

import axios from "axios";
import { USER_REQUEST } from "../user";
import JwtService from "@/common/jwt.service";

const state = {
  errors: null,
  userId: localStorage.getItem("userId") || null,
  isAuthenticated: !!JwtService.getAccessToken(),
  permissions: localStorage.getItem("permissions") || [],
};

const getters = {
  user: (s) => s.userId,
  isAuthenticated: (s) => !!s.isAuthenticated,
  permissions: (s) => s.permissions,
};

const actions = {
  [AUTH_LOGIN]: ({ commit, dispatch }, user) =>
    new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API + "token/", user)
        .then((resp) => {
          console.log(resp);
          // console.log(resp.data.permissions);
          dispatch(USER_REQUEST); // get user
          commit(SET_AUTH, resp);
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    }),

  [AUTH_REFRESH]: ({ commit, dispatch }) =>
    new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API + "token/refresh/", {
          refresh: JwtService.getRefreshToken(),
        })
        .then((resp) => {
          commit(SET_AUTH, resp);
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    }),

  [AUTH_LOGOUT]: ({ commit, dispatch, state }) => {
    commit(PURGE_AUTH);
  },
};

const mutations = {
  [SET_AUTH]: (state, payload, dispatch) => {
    state.isAuthenticated = true;
    state.userId = JSON.parse(atob(payload.data.access.split(".")[1]))[
      "user_id"
    ];
    state.permissions = payload.data.permissions;
    state.errors = {};
    JwtService.saveAccessToken(payload.data.access);
    JwtService.saveRefreshToken(payload.data.refresh);
    localStorage.setItem("userId", state.userId);
    console.log(payload.data);
    state.permissions &&
      localStorage.setItem("permissions", JSON.stringify(state.permissions));
  },
  [AUTH_ERROR]: (state, err) => {
    state.errors = err;
  },
  [PURGE_AUTH]: (state) => {
    JwtService.destroyAccessToken();
    JwtService.destroyRefreshToken();
    localStorage.removeItem("userId");
    localStorage.removeItem("permissions");
    state.isAuthenticated = false;
    state.userId = null;
    state.permissions = null;
    state.errors = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
