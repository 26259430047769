<template>
<div>
    <input
      class="modal-text"
      type="text"
      placeholder="Start typing to search..."
      @input="onChange"
      v-model="search"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
    />
    <ul
      id="autocomplete-results"
      v-show="isOpen"
      class="autocomplete-results"
    >
      <li
        class="loading"
        v-if="isLoading"
      >
        Loading results...
      </li>
      <li
        v-else
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result[modelName] }}
      </li>
    </ul>
  </div>
</template>

<script>
import Error from '../components/Error.vue'

  export default {
    name: 'AutocompleteSearch',
    props: {
      path: {
        type: String,
        required: false,
        default: 'contacts/organizations?ordering=name',
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false,
      },
      modelName: {
          type: String,
          required: false,
          default: 'name'
      },
      modelId: {
          type: String,
          required: false,
          default: 'id'   
      },
      errors: {
          required: false
      },
      field: {
          type: String,
          required: false,
      },
      message: {
          type: String,
          required: false,
      },
      search: {
          type: String,
          required: false,
          default: ""
      },
    },
    components: {
          // Error
      },
    data() {
      return {
        isOpen: false,
        results: [],
        isLoading: false,
        arrowCounter: -1,
        items: [],
      };
    },
    watch: {
      items: function (value, oldValue) {
        if (value.length !== oldValue.length) {
          this.results = value;
          this.isLoading = false;
        }
      },
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      setResult(result) {
        this.search = result[this.modelName];
        this.isOpen = false;
        this.$emit('selected', {'name': result[this.modelName], 'id': result[this.modelId]})
      },
      filterResults() {
        this.$api.get(this.path+'&search='+this.search)
            .then( response => {
                this.items  = response.data.results;
            });
      },
      onChange() {
        this.$emit('input', this.search);

        if (this.isAsync) {
          this.isLoading = true;
        } else {
          this.filterResults();
          this.isOpen = true;
        }
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      },
      onArrowDown() {
        if(this.arrowCounter < this.results.length - 1) {
            this.arrowCounter = this.arrowCounter + 1;
        } else {
            this.arrowCounter = 0;
        }
      },
      onArrowUp() {
        if(this.arrowCounter >= 1) {
            this.arrowCounter = this.arrowCounter - 1;
        } else {
            this.arrowCounter = this.results.length - 1;
        }
      },
      onEnter() {
        this.search = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;
      },
    },
  };
</script>

<style>
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    border: 1px solid var(--very-light-grey);
    overflow: auto; 
    background-color: var(--grey);
    color: var(--white);
    height: 150px;
    width: 70%;
    margin: 10px 10px 10px 0px;
    font-family: "roboto";
    border-radius: 6px;
    padding: 5px;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: var(--aqua);
    color: white;
  }
</style>