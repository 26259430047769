<template>
   <div :class="{'err': field in errors}">
        <slot></slot>
    <div class="errorMsg" v-if="field in errors"> 
        <div class="err-icon"></div>{{ message[0] }}</div>
    </div>
</template>

<script>
export default {
  props: {
      errors: {
        required: false,
      },

      field: {
        type: String,
        required: false,
      },

      message: {
        required: false,
        default: () => ['This field is required.']
      },
      
  },
}
</script>

<style>
.errorMsg {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
  width: 100%;
  
}

.errorMsg {
  display: none;
}

.err label {
  color: #cc0033;
}

.err input, 
.err select,
.err textarea {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

.err .errorMsg {
  display: flex; 
  width: 85%; 
  left: 15%; 
  position: relative; 
  
}

.err-icon {
    height: 17px;
    width: 17px;
    background-color: #cc0033;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-image: url("../assets/icons/error.svg");
    margin-right: 20px;
}

.err-icon,
.errorMsg {
  display: inline-block;
}
</style>